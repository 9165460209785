import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/react-accordion/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Root","List","displayName","Trigger","Content"] */ "/vercel/path0/node_modules/@radix-ui/react-tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["OptimizedImage"] */ "/vercel/path0/src/components/counselling/ArticleDetail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/freshdesk/FreshdeskOpenButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GrowthBookTracking"] */ "/vercel/path0/src/components/growthbook/GrowthBookTracking.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/HedepyLottie.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pageBlocks/carousel/EmblaCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/pageBlocks/components/VoucherForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/pageBlocks/CustomerSupportDot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/pageBlocks/SliderFeaturesDesktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/pageBlocks/SliderFeaturesMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/pageBlocks/TopicsBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/reviews/Review.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/StrapiBlocksRenderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/strapiForm/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/TherapistCardContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/TypeformWidget.tsx");
